<template>
  <div
    class="container connect-metamask"
  >
    <div class="row h-100">
      <div 
        class="
          col-12
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
        v-if="contractError"
      >
        <h1 class="font-primary text-center mb-4">An unexpected error occurred :(</h1>
        <p class="mb-4 text-center">
          An unexpected error occured while connecting to the smart contract, please <a style="text-decoration: underline" href="/connect">reload the page</a> and try again
        </p>
      </div>
      <div
        class="
          col-12
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
        v-if="noIdentity === true"
      >
        <h1 class="font-primary mb-4">Identity not found :(</h1>
        <p class="mb-4">
          We are sorry but your address is not whitelisted in any of our supported Blockchain registries.
        </p>
      </div>
      <div
        v-else-if="!metamaskAddress && noIdentity === false && !contractError"
        class="
          col-12
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
      >
        <h1 class="font-primary text-center mb-4">Connect Wallet</h1>
        <p class="mb-4">
          Please connect MetaMask in order to see assets and transactions. By
          connecting a wallet, you agree to Ten31 Terms of Service and
          acknowledge that you have read and understand the Blockchain protected
          stock protocol disclaimer.
        </p>
        <button @click="connectMetamask" class="button">
          Connect with MetaMask
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from '@vue/composition-api';
import {
  metamaskSetup,
  getCompaniesFromContract,
  checkIdentity
} from '../../main.ts';

export default defineComponent({
    data: function() {
        return {
            metamaskAddress: null,
            noIdentity: this.$store.state.noIdentity,
            contractError: false
        }
    },
    mounted() {
        if (!this.$store.state.noIdentity && this.$store.state.metamaskAddress) {
          return;
        }
        this.$store.watch(state => {
            this.noIdentity = state.noIdentity;
            this.metamaskAddress = state.metamaskAddress;
        });
        this.$store.subscribe((mutation, state) => {
            // getCompaniesFromContract();
            if (mutation.type === 'setNoIdentity') {
                if (state.noIdentity) {
                    this.noIdentity = true;
                } else {
                    this.noIdentity = false;
                    if (state.metamaskAddress) {
                      this.$router.push('/');
                    }
                }
            } else if (mutation.type === 'setMetamaskAddress') {
              // getCompaniesFromContract();
            }
            if (mutation.type === 'setContractError') {
              this.contractError = state.contractError;
            }
        });
    },
    methods: {
        connectMetamask() {
            metamaskSetup();
        }
    }
})
</script>
